//@ts-ignore
"use client";
import React, { useState } from "react";
import type { TableColumnsType, TableProps } from "antd";
import { Table, Typography } from "antd";
import { StatusBtn } from "../../components/index.ts";
import moment from "moment";

type OnChange = NonNullable<TableProps<DataType>["onChange"]>;
interface DataType {
  key: string;
  company: string;
  referred: string;
  payout: string;
  amount: string;
  total: string;
  status: any;
  view: any;
}

type GetSingle<T> = T extends (infer U)[] ? U : never;
type Sorts = GetSingle<Parameters<OnChange>[2]>;

const TableSection: React.FC<{
  tableData: DataType[];
  setIsViewOpen: any;
  setViewData: any
  // isShow: boolean;
}> = ({ tableData, setIsViewOpen, setViewData }: any) => {
  const [sortedInfo, setSortedInfo] = useState<Sorts>({});
  const { Text } = Typography;
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10; // Number of items per page
  const handleChange: OnChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter as Sorts);
  };

  const ButtonComponent = (item) => {
    return (
      <div className="flex items-center gap-4 justify-end ">
        <button
          onClick={() => {
            setIsViewOpen(true);
            setViewData(item?.item);
          }}
          className="font-semibold text-[#475467] hover:text-[#004EEB]"
        >
          View
        </button>
      </div>
    );
  };

  const mapDataToTableFormat = (tableData: any[]) => {
    return tableData?.map((item, index) => ({
      key: index,
      name: item.Name,
      // referredOn: item.Owner?.name ? item.Owner?.name : "---", //It's taking Owner's name instead of the Date company is referred on
      //   ? moment(item.add_time).subtract(10, "days").calendar()
      //   : "---",
      payout: item.last_payout_date ? moment(item.last_payout_date).format("DD/MM/YYYY") : "NA",
      referredOn: item.add_time ? moment(item.add_time).format("DD/MM/YYYY") : "NA",
      status: <StatusBtn item={item.Status} />,
      edit: <ButtonComponent item={item} />,
    }));
  };
  // @ts-ignore
  const tabledata: DataType[] = mapDataToTableFormat(tableData);

  const totalItems = tabledata?.length;
  const totalPages = Math.ceil(totalItems / pageSize);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const paginatedData = tabledata?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );
  const columns: TableColumnsType<DataType> = [
    {
      title: (
        <span className="flex items-center gap-2">
          Company
          <img
            src="https://ik.imagekit.io/8extk8sjo/Icon%20(32).svg?updatedAt=1710923979449"
            alt=""
          />
        </span>
      ),
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.company.length - b.company.length,
      sortOrder: sortedInfo.columnKey === "name" ? sortedInfo.order : null,
      ellipsis: true,
      className:
        "2xl:w-[400px] xxl:w-[400px] xl:w-[400px] w-auto py-10 h-[40px] text-[#475467]",
      sortDirections: [],
    },
    {
      title: (
        <span className="flex items-center gap-2">
          Referred On
          <img
            src="https://ik.imagekit.io/8extk8sjo/Icon%20(32).svg?updatedAt=1710923979449"
            alt=""
          />
        </span>
      ),
      dataIndex: "referredOn",
      key: "referredOn",
      sorter: (a, b) => a.referred.length - b.referred.length,
      sortOrder:
        sortedInfo.columnKey === "referredOn" ? sortedInfo.order : null,
      ellipsis: true,
      sortDirections: [],
      className: "w-[200px]",
    },
    {
      title: (
        <span className="flex items-center gap-2">
          Status
          <img
            src="https://ik.imagekit.io/8extk8sjo/Icon%20(32).svg?updatedAt=1710923979449"
            alt=""
          />
        </span>
      ),
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => a.status.length - b.status.length,
      sortOrder: sortedInfo.columnKey === "status" ? sortedInfo.order : null,
      ellipsis: true,
      sortDirections: [],
      className: "w-[200px]",
    },
    {
      title: (
        <span className="flex items-center gap-2">
          Last Payout
          <img
            src="https://ik.imagekit.io/8extk8sjo/Icon%20(32).svg?updatedAt=1710923979449"
            alt=""
          />
        </span>
      ),
      dataIndex: "payout",
      key: "payout",
      sorter: (a, b) => a.payout.length - b.payout.length,
      sortOrder: sortedInfo.columnKey === "payout" ? sortedInfo.order : null,
      ellipsis: true,
      sortDirections: [],
      className: "w-[200px]",
    },

    {
      title: "",
      dataIndex: "edit",
      key: "edit",
      ellipsis: true,
      className: "w-[100px]",
    },
  ];

  return (
    <>
      <div
        style={{
          boxShadow: "0px 1px 2px 0px #1018280F,0px 1px 3px 0px #1018281A",
        }}
        className="rounded-[12px] border border-[#EAECF0] bg-white overflow-hidden"
      >
        <Table
          columns={columns}
          dataSource={paginatedData}
          style={{
            borderTop: "1px solid #EAECF0",
          }}
          pagination={false}
        />

        <div className="w-full bg-white h-20 items-center  px-4  flex justify-between gap-4">
          <Text className="text-[#344054] text-[14px] font-medium ">
            Page {currentPage} of {totalPages}
          </Text>

          <div className="flex items-center gap-3">
            <button
              style={{
                boxShadow: "0px 1px 2px 0px #1018280D",
              }}
              className="rounded-[8px] font-semibold py-[8px] px-[14px] text-[#344054] border border-[#D0D5DD] hover:border-blue-500 text-sm hover:text-blue-500 transition-all"
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              Previous
            </button>
            <button
              style={{
                boxShadow: "0px 1px 2px 0px #1018280D",
              }}
              className="rounded-[8px] font-semibold py-[8px] px-[14px] text-[#344054] border border-[#D0D5DD] hover:border-blue-500 text-sm hover:text-blue-500 transition-all"
              disabled={currentPage === totalPages}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default TableSection;
