import React from "react";
import { Col, Row, Typography } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
const Main = () => {
  const { Title, Text } = Typography;
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email;
  const typeOfRequest = location.state?.type;
  return (
    <Row>
      <Col span={24}>
        <div className="w-full flex justify-center pt-28">
          <div>
            <div className="justify-center grid">
              <img
                src="https://ik.imagekit.io/8extk8sjo/Featured%20icon.svg?updatedAt=1709120630375"
                alt=""
              />
            </div>

            <Title
              style={{
                fontSize: "30px",
              }}
              className="pt-4 text-[#101828] text-center font-semibold"
            >
              Check your email
            </Title>
            <div className="grid text-center">
              <Text className="text-[16px] -mt-2">
                {typeOfRequest === "RESET_PASSWORD" ? (
                  <>
                    We sent a password reset link <br /> to{" "}
                    <strong>{email}</strong>
                  </>
                ) : (
                  "We sent an email verification link to your inbox"
                )}
              </Text>
              <Text
                style={{
                  color: "#475467",
                }}
                className=" text-[14px] pt-6"
              >
                Didn’t receive the email? {""}
                <span className="text-[#004EEB] cursor-pointer font-semibold text-[14px]">
                  Click to resend
                </span>
              </Text>
              <div className="text-center mt-8 flex justify-center gap-2 items-center ">
                <img
                  src="https://ik.imagekit.io/8extk8sjo/Icon%20(16).svg?updatedAt=1709123059299"
                  alt=""
                />
                <Text
                  onClick={() => navigate("/signin")}
                  style={{
                    color: "#475467",
                  }}
                  className=" text-[14px] cursor-pointer font-semibold"
                >
                  Back to log in
                </Text>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Main;
