// import React from "react";
// import { Typography } from "antd";
// import { Column } from "@ant-design/plots";

// const Graph = ({ isShow, graphData }: any) => {
//   const { Text } = Typography;


//   const data = graphData || [
//     { month: "Jan", region: "Converted", value: 0 },
//     { month: "Jan", region: "Visitors", value: 0 },
//     { month: "Jan", region: "Leads", value: 0 },
//     { month: "Feb", region: "Converted", value: 0 },
//     { month: "Feb", region: "Visitors", value: 0 },
//     { month: "Feb", region: "Leads", value: 0 },
//     { month: "Mar", region: "Converted", value: 0 },
//     { month: "Mar", region: "Visitors", value: 0 },
//     { month: "Mar", region: "Leads", value: 0 },
//     { month: "Apr", region: "Converted", value: 0 },
//     { month: "Apr", region: "Visitors", value: 0 },
//     { month: "Apr", region: "Leads", value: 0 },
//     { month: "May", region: "Converted", value: 0 },
//     { month: "May", region: "Visitors", value: 0 },
//     { month: "May", region: "Leads", value: 0 },
//     { month: "Jun", region: "Converted", value: 0 },
//     { month: "Jun", region: "Visitors", value: 0 },
//     { month: "Jun", region: "Leads", value: 0 },
//     { month: "Jul", region: "Converted", value: 0 },
//     { month: "Jul", region: "Visitors", value: 0 },
//     { month: "Jul", region: "Leads", value: 0 },
//     { month: "Aug", region: "Converted", value: 0 },
//     { month: "Aug", region: "Visitors", value: 0 },
//     { month: "Aug", region: "Leads", value: 0 },
//     { month: "Sep", region: "Converted", value: 0 },
//     { month: "Sep", region: "Visitors", value: 0 },
//     { month: "Sep", region: "Leads", value: 0 },
//     { month: "Oct", region: "Converted", value: 0 },
//     { month: "Oct", region: "Visitors", value: 0 },
//     { month: "Oct", region: "Leads", value: 0 },
//     { month: "Nov", region: "Converted", value: 0 },
//     { month: "Nov", region: "Visitors", value: 0 },
//     { month: "Nov", region: "Leads", value: 0 },
//     { month: "Dec", region: "Converted", value: 0 },
//     { month: "Dec", region: "Visitors", value: 0 },
//     { month: "Dec", region: "Leads", value: 0 },
//   ];

//   const config = {
//     data: isShow ? data : [],
//     xField: "month",
//     yField: "value",
//     colorField: "region",
//     colors: ["#ADD8E6", "#87CEEB", "#B0E0E6"], 
//     percent: true,
//     stack: true,
//     columnStyle: {
//       borderRadius: "50px", 
//     },
//     interaction: {
//       tooltip: {
//         shared: true,
//       },
//     },
//     tooltip: { channel: "y0", valueFormatter: ".0%" },
//   };

//   return (
//     <div
//       style={{
//         boxShadow: "0px 1px 2px 0px #1018280F,0px 1px 3px 0px #1018281A",
//       }}
//       className={`p-7 w-full flex rounded-[12px] bg-[#ffffff] border border-[#EAECF0] overflow-hidden ${
//         isShow ? "h-auto" : "h-[200px]"
//       }`}
//     >
//       <div className="w-full">
//         {data.length > 0 ? (
//           <Column
//             {...config}
//             className="w-full "
//             style={{ borderRadius: "8px" }}
//           />
//         ) : (
//           <Text>No data available</Text>
//         )}
//       </div>
  
//     </div>
//   );
// };

// export default Graph;

// import React from "react";
// import ReactApexChart from "react-apexcharts";

// const Graph: React.FC<{
//   isShow: any;
//   graphData: any
// }> = ({ isShow, graphData }: any) => {
//   console.log("Graph Data: ", graphData);
//   const series = [
//     {
//       name: "Visitors",
//       data: [130, 130, 130, 130, 130, 130, 130, 91, 125, 110, 95, 130]
//     },
//     {
//       name: "Leads",
//       data: [120, 120, 120, 120, 120, 120, 120, 120, 50, 55, 45, 60]
//     },
//     {
//       name: "Converted",
//       data: [70, 70, 70, 70, 70, 70, 70, 70, 70, 45, 50, 60]
//     }
//   ];

import React, { useMemo } from "react";
import ReactApexChart from "react-apexcharts";

const Graph: React.FC<{
  isShow: boolean;
  graphData: Array<{ month: string; region: string; value: number }> | undefined;
}> = ({ isShow, graphData }) => {
  const series = useMemo(() => {
    const regions = ["Visitors", "Leads", "Converted"];
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    
    if (!graphData || graphData.length === 0) {
      // Return default data if graphData is undefined or empty
      return regions.map(region => ({
        name: region,
        data: Array(12).fill(0)
      }));
    }
    
    return regions.map(region => ({
      name: region,
      data: months.map(month => {
        const dataPoint = graphData.find(d => d.month === month && d.region === region);
        return dataPoint ? dataPoint.value : 0;
      })
    }));
  }, [graphData]);
  
  // console.log("Series: ",series);
  // console.log("Graph Data: ",graphData);

  const options: ApexCharts.ApexOptions = {
    chart: {
      type: 'bar',
      stacked: true,
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 8,
        columnWidth: '30%' // Ensures columns fill the available width
      }
    },
    stroke: {
      width: 0,
    },
    xaxis: {
      categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    },
    yaxis: {
      title: {
        text: undefined
      },
      tickAmount: 5
    },
    tooltip: {
      y: {
        formatter: (val) => `${val}`
      }
    },
    fill: {
      opacity: 1
    },
    legend: {
      position: 'right',
      offsetY: 40
    },
    colors: ['#004EEB', '#2970FF', '#84ADFF'],
    dataLabels: {
      enabled: false // Disable data labels
    }
  };

  return (
    <div id="chart">
      <ReactApexChart options={options} series={series} type="bar" height={300} />
    </div>
  );
}

export default Graph;

