import React from "react";
import { Col, Row, Typography } from "antd";
import { Button, Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import axiosClient from "../../../utils/axios.tsx";
const Main = () => {
  const { Title, Text } = Typography;
  const navigate = useNavigate();

  // Define validation schema using Yup
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
  });

  return (
    <Row>
      <Col span={24}>
        <div className="w-full flex justify-center pt-28">
          <div className=" w-[500px]">
            <div className="justify-center grid">
              <img
                src="https://ik.imagekit.io/8extk8sjo/Featured%20icon%20(1).svg?updatedAt=1709121905924"
                alt=""
              />
            </div>

            <Title
              style={{
                fontSize: "30px",
              }}
              className="pt-4 text-[#101828] text-center font-semibold"
            >
              Forgot password?
            </Title>
            <div className="grid text-center">
              <Text className="text-[16px] -mt-2">
                No worries, we’ll send you reset instructions.
              </Text>
            </div>

            <div
              style={{
                background: "rgba(255, 255, 255, 1)",

                boxShadow:
                  "0px 2px 4px -2px rgba(16, 24, 40, 0.06),0px 4px 8px -2px rgba(16, 24, 40, 0.1)",
              }}
              className=" p-10 rounded-[12px] mt-8"
            >
              <Formik
                initialValues={{ email: "" }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                  axiosClient()
                    .get(`/users/request-reset-password/affiliate/${values.email}`)
                    .then((res) => {
                      setSubmitting(false);
                      navigate("/check-email", {state: {email: values.email, type: "RESET_PASSWORD"}});
                      toast.success("Email sent!");
                    })
                    .catch((error) => {
                      setSubmitting(false);
                      toast.error(error?.response?.data?.message);
                    });
                }}
              >
                {({ isSubmitting }) => (
                  <Form>
                    <Field
                      type="email"
                      name="email"
                      placeholder="Enter your email"
                      className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-red-500"
                    />
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="w-full h-[44px] mt-5 bg-[#155EEF] rounded-[8px] font-semibold text-[16px] text-[#fff]"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? <Spin /> : "Reset password"}
                    </Button>
                  </Form>
                )}
              </Formik>
            </div>
            <div className="text-center mt-8 flex justify-center gap-2 items-center ">
              <img
                src="https://ik.imagekit.io/8extk8sjo/Icon%20(16).svg?updatedAt=1709123059299"
                alt=""
              />
              <Text
                onClick={() => navigate("/signin")}
                style={{
                  color: "#475467",
                }}
                className=" text-[14px] cursor-pointer font-semibold"
              >
                Back to log in
              </Text>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Main;
