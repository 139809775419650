// import React, { useEffect, useRef } from 'react';
// import { Input, Select } from 'antd';
// import { FieldProps } from 'formik';
// import { allCountries } from 'country-telephone-data';

// const { Option } = Select;

// const PhoneNumberInput: React.FC<FieldProps & { selectedCountry: any }> = ({ field, form, selectedCountry }) => {
//   const prevSelectedCountryRef = useRef();

//   const handleCountryCodeChange = (value: string) => {
//     form.setFieldValue(field.name, `${value} ${field.value.split(' ')[1] || ''}`);
//   };

//   const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const countryCode = field.value.split(' ')[0] || '+1';
//     form.setFieldValue(field.name, `${countryCode} ${e.target.value}`);
//   };

//   useEffect(() => {
//     if (selectedCountry && selectedCountry.dialCode &&
//         (!prevSelectedCountryRef.current ||
//          prevSelectedCountryRef.current.value !== selectedCountry.value)) {
//       form.setFieldValue(field.name, `+${selectedCountry.dialCode} ${field.value.split(' ')[1] || ''}`);
//       prevSelectedCountryRef.current = selectedCountry;
//     }
//   }, [selectedCountry, form, field.name, field.value]);

//   useEffect(() => {
//     if (field.value && !field.value.startsWith('+')) {
//       form.setFieldValue(field.name, `+1 ${field.value}`);
//     }
//   }, []);

//   const [countryCode, localNumber] = field.value.split(' ');

//   const prefixSelector = (
//     <Select
//       value={countryCode || '+1'}
//       onChange={handleCountryCodeChange}
//       style={{ width: 100 }}
//       dropdownMatchSelectWidth={false}
//     >
//       {allCountries.map((country) => (
//         <Option key={country.iso2} value={`+${country.dialCode}`}>
//           +{country.dialCode}
//         </Option>
//       ))}
//     </Select>
//   );

//   return (
//     <Input
//       {...field}
//       addonBefore={prefixSelector}
//       placeholder="Enter your phone number"
//       value={localNumber || ''}
//       onChange={handlePhoneNumberChange}
//     />
//   );
// };

// export default PhoneNumberInput;

// import React, { useEffect } from 'react';
// import { Input, Select } from 'antd';
// import { FieldProps } from 'formik';
// import { allCountries } from 'country-telephone-data';

// const { Option } = Select;

// const PhoneNumberInput: React.FC<FieldProps> = ({ field, form }) => {
//   const handleCountryCodeChange = (value: string) => {
//     form.setFieldValue(field.name, `${value} ${field.value.split(' ')[1] || ''}`);
//   };

//   const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     const countryCode = field.value.split(' ')[0] || '+1';
//     form.setFieldValue(field.name, `${countryCode} ${e.target.value}`);
//   };

//   useEffect(() => {
//     if (field.value && !field.value.startsWith('+')) {
//       form.setFieldValue(field.name, `+1 ${field.value}`);
//     }
//   }, []);

//   const [countryCode, localNumber] = field.value.split(' ');

//   // Create a sorted list of unique country codes
//   const uniqueCountryCodes = [...new Set(allCountries.map(country => `+${country.dialCode}`))];
//   const sortedCountryCodes = uniqueCountryCodes.sort((a, b) => {
//     const numA = parseInt(a.slice(1));
//     const numB = parseInt(b.slice(1));
//     return numA - numB;
//   });

//   const prefixSelector = (
//     <Select
//       value={countryCode || '+1'}
//       onChange={handleCountryCodeChange}
//       style={{ width: 100 }}
//       dropdownMatchSelectWidth={false}
//     >
//       {sortedCountryCodes.map((code) => (
//         <Option key={code} value={code}>
//           {code}
//         </Option>
//       ))}
//     </Select>
//   );

//   return (
//     <Input
//       {...field}
//       addonBefore={prefixSelector}
//       placeholder="Enter your phone number"
//       value={localNumber || ''}
//       onChange={handlePhoneNumberChange}
//     />
//   );
// };

// export default PhoneNumberInput;

import React, { useEffect, useRef, useMemo } from "react";
import { Input, Select } from "antd";
import { FieldProps } from "formik";
import { allCountries } from "country-telephone-data";

const { Option } = Select;

const PhoneNumberInput: React.FC<FieldProps & { selectedCountry: any }> = ({
  field,
  form,
  selectedCountry,
}) => {
  const prevSelectedCountryRef = useRef();

  const uniqueDialCodes = useMemo(() => {
    const uniqueCodes = new Map();
    allCountries.forEach((country) => {
      if (!uniqueCodes.has(`+${country.dialCode}`)) {
        uniqueCodes.set(`+${country.dialCode}`, country);
      }
    });
    return Array.from(uniqueCodes.values()).sort(
      (a, b) => parseInt(a.dialCode) - parseInt(b.dialCode)
    );
  }, []);

  const handleCountryCodeChange = (value: string) => {
    form.setFieldValue(
      field.name,
      `${value} ${field.value.split(" ")[1] || ""}`
    );
  };

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const countryCode = field.value.split(" ")[0] || "+1";
    form.setFieldValue(field.name, `${countryCode} ${e.target.value}`);
  };

  useEffect(() => {
    if (
      selectedCountry &&
      selectedCountry.dialCode &&
      (!prevSelectedCountryRef.current ||
        prevSelectedCountryRef.current.value !== selectedCountry.value)
    ) {
      form.setFieldValue(
        field.name,
        `+${selectedCountry.dialCode} ${field.value.split(" ")[1] || ""}`
      );
      prevSelectedCountryRef.current = selectedCountry;
    }
  }, [selectedCountry, form, field.name, field.value]);

  useEffect(() => {
    if (field.value && !field.value.startsWith("+")) {
      form.setFieldValue(field.name, `+1 ${field.value}`);
    }
  }, []);

  const [countryCode, localNumber] = field.value.split(" ");

  const prefixSelector = (
    <Select
      value={countryCode || "+1"}
      onChange={handleCountryCodeChange}
      style={{ width: 100 }}
      dropdownMatchSelectWidth={false}
    >
      {uniqueDialCodes.map((country) => (
        <Option key={`+${country.dialCode}`} value={`+${country.dialCode}`}>
          +{country.dialCode}
        </Option>
      ))}
    </Select>
  );

  return (
    <Input
      {...field}
      addonBefore={prefixSelector}
      placeholder="Enter your phone number"
      value={localNumber || ""}
      onChange={handlePhoneNumberChange}
      autoComplete="off"
    />
  );
};

export default PhoneNumberInput;
